import classNames from 'classnames';
import { FC, ReactNode } from 'react';

export const ButtonV2Size = {
  small: 'py-2 px-4 text-sm',
  medium: 'py-3 px-5 text-base',
  large: 'py-3.5 px-6 text-lg',
};

export const ButtonV2Width = {
  full: 'w-full',
  auto: 'w-auto',
};

export const ButtonV2Rounded = {
  full: 'rounded-full',
  large: 'rounded-lg',
};

export const ButtonV2Style = {
  primary:
    'bg-grayv2-900 text-white hover:bg-grayv2-800 active:bg-grayv2-900 disabled:bg-grayv2-50 disabled:text-grayv2-300',
  secondary:
    'bg-orangev2-50 text-orangev2-500 hover:bg-orangev2-100 active:bg-orangev2-50 disabled:bg-orangev2-50 disabled:text-orangev2-200',
  text: 'bg-transparent text-grayv2-700 hover:text-grayv2-900 active:text-grayv2-700 disabled:text-grayv2-300',
};

export type IButtonV2Props = {
  label?: string;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  variantClassName: string;
  buttonProps?: Partial<JSX.IntrinsicElements['button']>;
};

const ButtonV2: FC<IButtonV2Props> = (props) => {
  const {
    label,
    children,
    variantClassName,
    iconLeft,
    iconRight,
    buttonProps,
  } = props;

  return (
    <button
      className={classNames(
        'flex items-center justify-center space-x-2.5 whitespace-nowrap font-noto-sans font-semibold focus:outline-none',
        variantClassName,
      )}
      {...buttonProps}
    >
      {iconLeft}
      <p>{label}</p>
      {children}
      {iconRight}
    </button>
  );
};

export default ButtonV2;
