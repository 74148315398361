import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { FC, Fragment, ReactNode, useRef, useState } from 'react';
import {
  HiInformationCircle,
  HiOutlineX,
  HiCheck,
  HiOutlineInformationCircle,
} from 'react-icons/hi';
import ButtonV2, {
  ButtonV2Rounded,
  ButtonV2Size,
  ButtonV2Style,
  ButtonV2Width,
  IButtonV2Props,
} from './Button';

export const ActionSection = {
  alignEnd: 'flex justify-end space-x-4',
  alignEndDesktopOnly: 'lg:flex lg:justify-end lg:space-x-4',
  sideBySide: 'flex space-x-4',
  sticky: '-mx-6 px-6 -mb-6 lg:py-3 py-4 lg:rounded-b-2xl',
  bgGrayMobileOnly: 'bg-grayv2-50 lg:bg-transparent',
  bgGray: 'bg-grayv2-50',
};

interface IModalV2Base {
  isOpen: ReturnType<typeof useModalV2>['isOpen'];
  toggle: ReturnType<typeof useModalV2>['toggle'];
  closable?: boolean;
  className?: string;
}
const ModalV2Base: FC<IModalV2Base> = (props) => {
  const { isOpen, toggle, closable = true, children, className } = props;
  const focusElelement = useRef();

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={() => closable && toggle(false)}
        initialFocus={focusElelement}
        className="fixed inset-0 z-50 drop-shadow-sm"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-20"
          leave="ease-in duration-200"
          leaveFrom="opacity-20"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <section
            ref={focusElelement}
            className={classNames(
              'fixed inset-0 mt-20 rounded-t-3xl bg-white lg:m-auto lg:h-fit lg:w-fit lg:min-w-384px lg:rounded-3xl',
              className,
            )}
          >
            {closable && (
              <HiOutlineX
                className="absolute right-6 top-4 z-50 block h-6 w-6 cursor-pointer font-bold text-grayv2-500 lg:-mr-3"
                onClick={() => toggle(false)}
              />
            )}
            {children}
          </section>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

// Desktop: https://www.figma.com/file/Qpo3OjZiPR0x07OqbgWlMG/CLP-Design-sysytem?node-id=343%3A66068&t=qf8sO4RiTI7Lpv6f-0
// Mobile: https://www.figma.com/file/Qpo3OjZiPR0x07OqbgWlMG/CLP-Design-sysytem?node-id=343%3A66068&t=qf8sO4RiTI7Lpv6f-0
export interface IModalV2Layout extends IModalV2Base {
  icon?: ReactNode;
  title?: ReactNode;
  caption?: ReactNode;
  header?: ReactNode;
  actionSection?: ReactNode;
  actionSectionClassName?: string;
  baseModalClassName?: string;
}
export const ModalV2OneCol: FC<IModalV2Layout> = (props) => {
  const {
    icon,
    title,
    caption,
    actionSection,
    actionSectionClassName,
    children,
    baseModalClassName,
    ...restBaseProps
  } = props;
  return (
    <ModalV2Base {...restBaseProps} className={baseModalClassName}>
      <div className="flex h-full flex-col place-content-between p-6 lg:h-auto">
        <section
          className={classNames('overflow-auto lg:max-h-168 lg:max-w-768px')}
        >
          <Dialog.Title className="sticky top-0 bg-white pb-4 text-subheadingv2 font-semibold lg:pb-4">
            {icon && <div className="flex justify-center">{icon}</div>}
            <div
              className={classNames({ 'mt-5': !!icon }, 'flex justify-center')}
            >
              {title}
            </div>
            {caption && (
              <div className="mt-2 flex justify-center text-p1 font-normal text-grayv2-500">
                {caption}
              </div>
            )}
          </Dialog.Title>
          {children}
        </section>
        {actionSection && (
          <section className={classNames('pt-4', actionSectionClassName)}>
            {actionSection}
          </section>
        )}
      </div>
    </ModalV2Base>
  );
};

// Desktop: https://www.figma.com/file/Qpo3OjZiPR0x07OqbgWlMG/CLP-Design-sysytem?node-id=343%3A66068&t=qf8sO4RiTI7Lpv6f-0
// Mobile: https://www.figma.com/file/Qpo3OjZiPR0x07OqbgWlMG/CLP-Design-sysytem?node-id=343%3A66068&t=qf8sO4RiTI7Lpv6f-0
export const ModalV2TwoCol: FC<IModalV2Layout> = (props) => {
  const {
    icon,
    title,
    caption,
    header,
    actionSection,
    actionSectionClassName,
    children,
    className,
    baseModalClassName,
    ...restBaseProps
  } = props;
  return (
    <ModalV2Base {...restBaseProps} className={baseModalClassName}>
      <div
        className={classNames(
          'flex h-full flex-col place-content-between p-6 lg:h-auto',
          className,
        )}
      >
        <section className="scrollbar-hide overflow-auto lg:max-h-168 lg:max-w-768px">
          {header || (
            <Dialog.Title
              className={classNames(
                'sticky top-0 z-40 bg-white pb-4 text-subheadingv2 font-semibold lg:flex',
                {
                  'lg:items-top lg:space-x-4': !!icon,
                },
              )}
            >
              {icon && <div className="lg:flex lg:justify-center">{icon}</div>}
              <div className="mt-4 lg:mt-0 lg:pr-4">
                <div>{title}</div>
                {caption && (
                  <div className="mt-2 text-p1 font-normal text-grayv2-500">
                    {caption}
                  </div>
                )}
              </div>
            </Dialog.Title>
          )}
          {children}
        </section>
        {actionSection && (
          <section className={classNames(actionSectionClassName)}>
            {actionSection}
          </section>
        )}
      </div>
    </ModalV2Base>
  );
};

export function withModalIconBackground(icon: ReactNode, className?: string) {
  return (
    <div className={classNames('h-fit w-fit rounded-full p-2', className)}>
      {icon}
    </div>
  );
}

export function withInfoRedIcon() {
  return withModalIconBackground(
    <HiOutlineInformationCircle className="h-5 w-5 text-redv2-500" />,
    'bg-redv2-50',
  );
}

export function withCheckGreenIcon() {
  return withModalIconBackground(
    <HiCheck className="h-5 w-5 text-greenv2-500" />,
    'bg-greenv2-50',
  );
}

export function useModalV2<Ctx>(): {
  isOpen: boolean;
  toggle(bool?: boolean, context?: Ctx): void;
  context?: Ctx;
} {
  const [isOpen, setOpen] = useState(false);
  const [context, setContext] = useState(null);

  return {
    isOpen,
    context,
    toggle(bool?: boolean, context?: Ctx) {
      setOpen(bool ?? !isOpen);
      setContext(context);
    },
  };
}

// --- action section presets by layout
const smallBtnDesktopClassName = 'lg:py-2 lg:px-5 lg:text-sm';
export function bulidCenterOneActionButton(
  primaryBtnProps?: Partial<IButtonV2Props>,
  className?: string,
) {
  return {
    actionSection: (
      <ButtonV2
        variantClassName={classNames(
          ButtonV2Style.primary,
          ButtonV2Rounded.large,
          ButtonV2Width.full,
          ButtonV2Size.large,
          smallBtnDesktopClassName,
        )}
        {...primaryBtnProps}
      />
    ),
    actionSectionClassName: classNames('sticky -bottom-6', className),
  };
}

export function buildRightOneActionButton(
  primaryBtnProps?: Partial<IButtonV2Props>,
  className?: string,
): Pick<IModalV2Layout, 'actionSection' | 'actionSectionClassName'> {
  return {
    actionSection: (
      <ButtonV2
        variantClassName={classNames(
          ButtonV2Style.primary,
          ButtonV2Rounded.large,
          ButtonV2Size.large,
          smallBtnDesktopClassName,
          'ml-auto',
        )}
        {...primaryBtnProps}
      />
    ),
    actionSectionClassName: classNames('sticky -bottom-6', className),
  };
}

export function buildCenterTwoActionButton(
  primaryBtnProps?: Partial<IButtonV2Props>,
  secondaryBtnProps?: Partial<IButtonV2Props>,
  className?: string,
) {
  return {
    actionSection: (
      <>
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.text,
            ButtonV2Rounded.large,
            ButtonV2Width.full,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
          )}
          {...secondaryBtnProps}
        />
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.primary,
            ButtonV2Rounded.large,
            ButtonV2Width.full,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
          )}
          {...primaryBtnProps}
        />
      </>
    ),
    actionSectionClassName: classNames(ActionSection.sideBySide, className),
  };
}

export function buildRightTwoActionButton(
  primaryBtnProps?: Partial<IButtonV2Props>,
  secondaryBtnProps?: Partial<IButtonV2Props>,
  className?: string,
) {
  return {
    actionSection: (
      <>
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.text,
            ButtonV2Rounded.large,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
            'w-full lg:w-fit',
          )}
          {...secondaryBtnProps}
        />
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.primary,
            ButtonV2Rounded.large,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
            'w-full lg:w-fit',
          )}
          {...primaryBtnProps}
        />
      </>
    ),
    actionSectionClassName: classNames(
      ActionSection.alignEndDesktopOnly,
      ActionSection.sticky,
      'flex flex-col-reverse lg:flex-row',
      className,
    ),
  };
}

// for one odd padding https://www.figma.com/file/Qpo3OjZiPR0x07OqbgWlMG/CLP-Design-sysytem?node-id=343%3A66068&t=S1ydOLNHZ5Bb4PI0-0
// can assign py-3 in className parameter
export function buildRightTwoActionButtonDesktopSideBybSideMobile(
  primaryBtnProps?: Partial<IButtonV2Props>,
  secondaryBtnProps?: Partial<IButtonV2Props>,
  className?: string,
) {
  return {
    actionSection: (
      <>
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.text,
            ButtonV2Rounded.large,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
            'w-full lg:w-fit',
          )}
          {...secondaryBtnProps}
        />
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.primary,
            ButtonV2Rounded.large,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
            'w-full lg:w-fit',
          )}
          {...primaryBtnProps}
        />
      </>
    ),
    actionSectionClassName: classNames(
      ActionSection.alignEndDesktopOnly,
      ActionSection.sideBySide,
      ActionSection.sticky,
      'bg-grayv2-50',
      className,
    ),
  };
}

export function buildCenterTwoPrimaryActionButtonDesktopSideBySideRowMobile(
  firstBtnProps?: Partial<IButtonV2Props>,
  secondBtnProps?: Partial<IButtonV2Props>,
  className?: string,
): Pick<IModalV2Layout, 'actionSection' | 'actionSectionClassName'> {
  return {
    actionSection: (
      <>
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.primary,
            ButtonV2Rounded.large,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
          )}
          {...firstBtnProps}
        />
        <ButtonV2
          variantClassName={classNames(
            ButtonV2Style.primary,
            ButtonV2Rounded.large,
            ButtonV2Size.large,
            smallBtnDesktopClassName,
          )}
          {...secondBtnProps}
        />
      </>
    ),
    actionSectionClassName: classNames(
      'mx-auto gap-4 flex flex-col w-full lg:flex-row justify-center',
      ActionSection.sticky,
      className,
    ),
  };
}
// --- end of action section presets by layout

// -- header section
export interface IHeader {
  title: ReactNode;
  caption?: ReactNode;
  icon?: ReactNode;
  className?: string;
}
export const HeaderIconTextSideBySide: FC<IHeader> = (props) => {
  const { title, caption, icon, className } = props;
  return (
    <Dialog.Title
      className={classNames(
        'sticky top-0 flex items-start space-x-4 bg-white pb-4 text-subheadingv2 font-semibold',
        {
          'lg:items-top lg:space-x-4': !!icon,
        },
        className,
      )}
    >
      {icon && <div className="lg:flex lg:justify-center">{icon}</div>}
      <div className="lg:mt-0 lg:pr-4">
        <div>{title}</div>
        {caption && (
          <div className="mt-2 text-p1 text-grayv2-500">{caption}</div>
        )}
      </div>
    </Dialog.Title>
  );
};

export const HeaderIconTextSideBySideDesktopRowMobile: FC<IHeader> = (
  props,
) => {
  const { title, caption, icon, className } = props;
  return (
    <Dialog.Title
      className={classNames(
        'sticky top-0 bg-white pb-4 text-subheadingv2 font-semibold lg:flex',
        {
          'lg:items-top lg:space-x-4': !!icon,
        },
        className,
      )}
    >
      {icon && <div className="lg:flex lg:justify-center">{icon}</div>}
      <div className="mt-4 lg:mt-0 lg:pr-4">
        <div>{title}</div>
        {caption && (
          <div className="mt-2 text-p1 text-grayv2-500">{caption}</div>
        )}
      </div>
    </Dialog.Title>
  );
};
// -- end of header section
