import { flow } from 'lodash';
import { ReactElement } from 'react';

export type IOFlowParams<T> = {
  out: (props: T) => ReactElement;
  inProps: Readonly<T>;
};

export function decorate(
  props: any,
  compInit: (props: any) => ReactElement,
  operations: any[],
) {
  const payload = {
    inProps: props,
    out: compInit,
  };

  const { out } = flow([...operations])(payload);

  return out(props);
}
