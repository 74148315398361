/**
 * SelectV2 is equivalent to Select component in react-select
 * Using alias in case of we turning it to component
 * 
 * Usage example
 * ```
 * <Select 
    {...stylizeSeac({ icon: iconPreset.DotsVertical})} 
    options={[
      { label: '1', value: 1 },
      { label: <div>2</div>, value: 2 },
      { label: <div>3</div>, value: { foo: 'bar' } },
    ]}
  />
 * ```
 */

import { GroupBase, OptionProps, Props } from 'react-select';
import {
  CheckIcon,
  ChevronDownIcon,
  DotsVerticalIcon,
  SelectorIcon,
} from '@heroicons/react/outline';
import cx from 'classnames';
import { defaultTo, isEqual, merge } from 'lodash';
import SelectComponent from 'react-select';
import AsyncSelectComponent, { AsyncProps } from 'react-select/async';
import { FieldProps } from 'formik';
import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { decorate, IOFlowParams } from '@utils/componentDecorator';
import { IModalV2Layout, ModalV2TwoCol } from './ModalV2';
import { useModal } from '@ui-kit/HeadlessModal';
import { useResponsive } from '@hooks/useResponsive';
import { Field } from 'formik';
import { HiX } from 'react-icons/hi';
import classNames from 'classnames';
import { Close } from '@ui-kit/icons';

export const SelectV2 = SelectComponent;
export const AsyncSelectV2 = AsyncSelectComponent;

export type SelectOption<T = any> = {
  label: string;
  value: T;
  isDisabled?: boolean;
};

export const iconPreset = {
  ChevronDown: <ChevronDownIcon className="h-5 w-5 text-grayv2-500" />,
  Selector: <SelectorIcon className="h-6 w-6 text-grayv2-500" />,
  DotsVertical: <DotsVerticalIcon className="h-6 w-6 text-shadesv2-100" />,
  DotsVerticalCircle: (
    <div className="rounded-full border p-1">
      <DotsVerticalIcon className="h-6 w-6 text-shadesv2-100" />
    </div>
  ),
};

export type StylingOptions = {
  icon?: JSX.Element;
  classNames?: Partial<Props['classNames']>;
};

type OptionRenderer = {
  comparatorFn: (props: PropsWithChildren<OptionProps>) => boolean;
  renderer: (props: PropsWithChildren<OptionProps>) => ReactNode;
};
function getDefaultOptionRenderer(): OptionRenderer {
  return {
    comparatorFn: (props: PropsWithChildren<OptionProps<SelectOption>>) => {
      return !!props.getValue().find((option: SelectOption) => {
        return isEqual(option?.value, props.data?.value);
      });
    },
    renderer: (props: PropsWithChildren<OptionProps<SelectOption>>) => (
      <div>{props.children}</div>
    ),
  };
}

export function stylizeSeac<T = SelectOption>(
  options?: StylingOptions,
  optionRenderer: OptionRenderer = getDefaultOptionRenderer(),
) {
  const classNames: Props['classNames'] = {
    ...options?.classNames,
    container(props) {
      return cx(
        'border border-grayv2-200 rounded-lg hover:border-grayv2-500 focus:border-grayv2-900 text-left',
        props.isDisabled ? 'bg-grayv2-50' : 'bg-shadesv2-0',
        options?.classNames?.container?.(props),
      );
    },
    menuList(props) {
      return cx(
        'mt-2 rounded-lg bg-shadesv2-0 filter drop-shadow-md',
        options?.classNames?.menuList?.(props),
      );
    },
    option(props) {
      return cx(
        'px-3 py-2 hover:bg-orangev2-100 focus:bg-orangev2-400 cursor-pointer',
        { 'text-grayv2-500': props.isDisabled },
        options?.classNames?.option?.(props),
      );
    },
    indicatorsContainer(props) {
      return cx(
        'px-2',
        props.isDisabled ? 'text-grayv2-500' : 'text-grayv2-900',
        options?.classNames?.indicatorsContainer?.(props),
      );
    },
    valueContainer(props) {
      return cx(
        'px-4 py-2 text-sm',
        props.isDisabled ? 'text-grayv2-500' : 'text-grayv2-900',
        options?.classNames?.valueContainer?.(props),
      );
    },
    placeholder() {
      return 'text-p2 text-grayv2-500';
    },
    noOptionsMessage() {
      return 'text-p2 px-3 py-2';
    },
  };

  const components: Props<T>['components'] = {
    DropdownIndicator(props) {
      return (
        <ChevronDownIcon
          className={cx('h-5 w-5', {
            'invisible opacity-0':
              props.selectProps.menuIsOpen && !props.hasValue,
            'h-5 w-px': props.selectProps.menuIsOpen && props.hasValue,
          })}
          aria-hidden="true"
        />
      );
    },
    Option(props) {
      const { innerRef, getClassNames, innerProps } = props;
      const hasSelected = optionRenderer.comparatorFn(props);

      return (
        <div
          ref={innerRef}
          className={cx(
            getClassNames('option', props),
            'flex items-center justify-between text-p1',
          )}
          {...innerProps}
        >
          {optionRenderer.renderer(props)}
          {hasSelected && <CheckIcon className="h-6 w-6" />}
        </div>
      );
    },
    MultiValueRemove(props) {
      const { innerProps, children } = props;

      function handleOnClick(event: React.MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
        event.preventDefault();
        innerProps.onClick?.(event);
      }

      return (
        <div
          role="button"
          {...innerProps}
          className={cx('flex items-center', innerProps.className)}
          onClick={handleOnClick}
        >
          <div>{children}</div>
          <div>
            <HiX />
          </div>
        </div>
      );
    },
    ClearIndicator(props) {
      const { innerProps } = props;
      return (
        <div {...innerProps}>
          <Close
            className={cx('h-5 w-5 text-gray-600', {
              hidden: !props.selectProps.menuIsOpen,
            })}
            aria-hidden="true"
          />
        </div>
      );
    },
  };

  return {
    unstyled: true,
    classNames,
    components,
  };
}

export function buildPropsFormikSupportSingleSelect(
  fieldProps: FieldProps,
  options: SelectOption[],
  valueFn?: (
    value: FieldProps['field']['value'],
    options: SelectOption[],
  ) => SelectOption | null, // for getting value to match selected option, useful when having value as object
) {
  const { name, value } = fieldProps.field;
  const { setFieldValue, setFieldTouched } = fieldProps.form;

  return {
    name,
    value: defaultTo(
      valueFn
        ? valueFn(value, options)
        : options.find((option) => isEqual(option.value, value)),
      null,
    ),
    onChange: (option: SelectOption) => setFieldValue(name, option?.value),
    onBlur: () => setFieldTouched(name, true),
    options,
  };
}

export function buildPropsFormikSupportMultiSelect(
  fieldProps: FieldProps,
  options: SelectOption[],
  valueFn?: (
    value: FieldProps['field']['value'],
    options: SelectOption[],
  ) => SelectOption | null, // for getting value to match selected option, useful when having value as object
) {
  const { name, value } = fieldProps.field;
  const { setFieldValue } = fieldProps.form;

  return {
    name,
    value:
      valueFn?.(value, options) ||
      options.filter((option) => value.includes(option.value)),
    onChange: (option: SelectOption[]) => {
      setFieldValue(
        name,
        option.map((opt) => opt.value),
      );
    },
    options,
  };
}

export function buildPropsFormikSupportSingleAsyncSelect(
  fieldProps: FieldProps,
) {
  const { name, value } = fieldProps.field;
  const { setFieldValue } = fieldProps.form;

  return {
    name,
    value,
    onChange: (option: SelectOption) => setFieldValue(name, option),
    defaultOptions: true,
  };
}

export function optionOnModalStyles(): Pick<
  StateManagerProps,
  'menuPosition' | 'menuPortalTarget' | 'styles'
> {
  return {
    menuPosition: 'fixed',
    menuPortalTarget: typeof window !== 'undefined' ? document.body : undefined,
    styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
  };
}

type SelectFlowParams = IOFlowParams<StateManagerProps<SelectOption>>;
export class SelectV2Decorator {
  static decorate(
    props: StateManagerProps | AsyncProps<any, any, any>,
    Element: SelectComponent | AsyncSelectComponent,
    operations: any[],
  ) {
    return decorate(
      props,
      (outProps: StateManagerProps) => <Element {...outProps} />,
      operations,
    );
  }

  static withResponsive(
    modalProps?: Partial<IModalV2Layout>,
    containerClassName?: string,
  ) {
    return ({ out, inProps }: SelectFlowParams): SelectFlowParams => ({
      inProps,
      out: (outProps) => (
        <Responsive {...{ out, outProps, modalProps, containerClassName }} />
      ),
    });
  }

  static withFormikFieldSingleSelect(
    name: string,
    valueFn?: (value: any, options: SelectOption[]) => SelectOption | null,
  ) {
    return ({ out, inProps }: SelectFlowParams): SelectFlowParams => ({
      inProps,
      out: (outProps) => (
        <Field name={name}>
          {(fieldProps: FieldProps) =>
            out(
              merge<typeof outProps, Partial<typeof outProps>>(outProps, {
                ...buildPropsFormikSupportSingleSelect(
                  fieldProps,
                  outProps.options as SelectOption[],
                  valueFn,
                ),
                classNames: {
                  container: (props) =>
                    cx(stylizeSeac().classNames.container(props), {
                      'border border-redv2-200 hover:border-redv2-300 focus:border-redv2-400 focus:border-2 rounded-lg':
                        fieldProps.meta.touched && fieldProps.meta.error,
                    }),
                },
              }),
            )
          }
        </Field>
      ),
    });
  }
}

interface IResponsive {
  out: SelectFlowParams['out'];
  outProps: StateManagerProps<SelectOption>;
  modalProps?: Partial<IModalV2Layout>;
  containerClassName?: string;
}
const Responsive: FC<IResponsive> = (props) => {
  const { out, outProps, modalProps, containerClassName } = props;
  const { options } = outProps;
  const modal = useModal();
  const { lg } = useResponsive();

  useEffect(() => {
    modal.toggle(false);
  }, [lg]);

  if (lg) {
    const newProps = merge<
      StateManagerProps<SelectOption>,
      StateManagerProps<SelectOption>
    >(outProps, { openMenuOnClick: true });
    return out(newProps);
  }

  const newProps = merge<
    StateManagerProps<SelectOption>,
    StateManagerProps<SelectOption>
  >(outProps, {
    menuIsOpen: false,
    isSearchable: false,
    onMenuOpen: () => modal.toggle(true),
  });

  return (
    <>
      <div className={containerClassName}>{out(newProps)}</div>
      <ModalV2TwoCol {...merge(modal, modalProps)}>
        {options.map((option, index) => (
          <span
            key={index}
            className={classNames(
              'block cursor-pointer px-3 py-2 text-p1',
              (option as SelectOption).isDisabled
                ? 'text-grayv2-500'
                : 'text-grayv2-900',
            )}
            onClick={(event) =>
              !(option as SelectOption).isDisabled &&
              handleOnSelectOption(option, event)
            }
          >
            {option.label}
          </span>
        ))}
      </ModalV2TwoCol>
    </>
  );

  function handleOnSelectOption(
    option: SelectOption | GroupBase<SelectOption>,
    event: any,
  ) {
    const group = (option as GroupBase<SelectOption>).options;
    if (group) {
      outProps.onChange(group, { action: 'select-option', option: group[0] });
    } else {
      const payload = option as SelectOption;
      if (Array.isArray(outProps.value)) {
        const newValue = outProps.value.concat(payload);
        outProps.onChange(newValue, {
          action: 'select-option',
          option: payload,
        });
      } else {
        outProps.onChange(payload, {
          action: 'select-option',
          option: payload,
        });
      }
    }
    modal.toggle(false);
  }
};
